<template>
  <div>
    <input type="file" ref="file" style="display: none" @change="uploadFile" />

    <div id="intro">
      <div class="cover" v-if="getBanner">
        <img :src="getBanner" />
      </div>

      <div class="section">
        <div class="meta">
          <div class="userpic">
            <img :src="getAvatar" />
          </div>
        </div>
      </div>
    </div>

    <div id="edit-profile" class="section">
      <h1>Edit profile</h1>

      <form @submit.prevent="updateUserInfo">
        <div class="flex">
          <div>
            <div class="fel">
              <div class="fel-label">Username</div>
              <input
                type="text"
                name="username"
                v-model="getNickName"
                :class="{ error: nickNameError }"
              />
              <div class="fel-error">{{ nickNameError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">First Name</div>
              <input
                type="text"
                name="firstname"
                v-model="getName"
                :class="{ error: nameError }"
              />
              <div class="fel-error">{{ nameError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Last Name</div>
              <input
                type="text"
                name="lastname"
                v-model="getSurname"
                :class="{ error: surnameError }"
              />
              <div class="fel-error">{{ surnameError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Email Address</div>
              <input
                type="email"
                name="email"
                v-model="getEmail"
                :class="{ error: emailError }"
              />
              <div class="fel-error">{{ emailError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Bio</div>
              <textarea
                name="bio"
                v-model="getDescription"
                :class="{ error: descriptionError }"
              />
              <div class="fel-error">{{ descriptionError }}</div>
            </div>
          </div>

          <div>
            <div class="fel">
              <div class="fel-label">Website</div>
              <input
                type="text"
                v-model="getWebsite"
                :class="{ error: websiteError }"
              />
              <div class="fel-error">{{ websiteError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Facebook</div>
              <input
                type="text"
                v-model="getFacebook"
                :class="{ error: facebookError }"
              />
              <div class="fel-error">{{ facebookError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Twitter</div>
              <input
                type="text"
                v-model="getTwitter"
                :class="{ error: twitterError }"
              />
              <div class="fel-error">{{ twitterError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Discord</div>
              <input
                type="text"
                v-model="getDiscord"
                :class="{ error: discordError }"
              />
              <div class="fel-error">{{ discordError }}</div>
            </div>

            <div class="fel">
              <div class="fel-label">Instagram</div>
              <input
                type="text"
                v-model="getInstagram"
                :class="{ error: instagramError }"
              />
              <div class="fel-error">{{ instagramError }}</div>
            </div>
          </div>

          <div>
            <div class="fel">
              <div class="fel-label">Userpic</div>

              <div class="fel-file" @click="setFileType('Avatar')" v-if="getAvatar">
                <img :src="getAvatar" />
                <button type="button" class="round">Change Userpic</button>
                <div class="fel-description">AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.</div>
                <div class="fel-description">Recommended minimum image size requirements: 220&nbsp;×&nbsp;220</div>
              </div>

              <div class="fel-file" @click="setFileType('Avatar')" v-else>
                <div class="svg">
                  <svg><use xlink:href="#svg-upload" /></svg>
                </div>
                <div class="fel-title">Choose a&nbsp;file or&nbsp;drag it&nbsp;here.</div>
                <div class="fel-description">AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.</div>
                <div class="fel-description">Recommended minimum image size requirements: 220&nbsp;×&nbsp;220</div>
              </div>
            </div>
          </div>

          <div>
            <div class="fel">
              <div class="fel-label">Cover</div>

              <div class="fel-file" @click="setFileType('Banner')" v-if="getBanner">
                <img :src="getBanner" />
                <button type="button" class="round">Change Cover</button>
                <div class="fel-description">AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.</div>
                <div class="fel-description">Recommended minimum image size requirements: 1920&nbsp;×&nbsp;520</div>
              </div>

              <div class="fel-file" @click="setFileType('Banner')" v-else>
                <div class="svg">
                  <svg><use xlink:href="#svg-upload" /></svg>
                </div>
                <div class="fel-title">Choose a&nbsp;file or&nbsp;drag it&nbsp;here.</div>
                <div class="fel-description">AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.</div>
                <div class="fel-description">Recommended minimum image size requirements: 1920&nbsp;×&nbsp;520</div>
              </div>
            </div>
          </div>
        </div>

        <button class="button green" type="submit" :disabled="disableSaveBtn">Save</button>
      </form>
    </div>

    <!-- <div class="settings-mode">
      <div class="input">
        <div class="label">Dark Mode</div>
        <div class="radio">
          <label v-for="(item, index) in themeModes" :key="index">
            <input
              type="radio"
              :name="item.value"
              :value="item"
              v-model="themeMode"
              @change="toggleTheme(item)"
            />
            <span class="cell"></span>
            <span class="text">{{ item.text }}</span>
          </label>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import openFileInputMixin from "@/mixins/openFileInputMixin";
import http from "@/util/http-common";
import api from "@/api/api";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import cloudinary from "@/util/cloudinary";
import { mapActions, mapGetters, mapMutations } from "vuex";
import validateRegexMixin from "@/mixins/validateRegexMixin";
import setThemeMixin from "@/mixins/setThemeMixin";
import errors from "@/util/errors";

export default {
  name: "Settings",
  mixins: [
    openFileInputMixin,
    setProcessingMixin,
    validateRegexMixin,
    setThemeMixin,
  ],
  data: () => ({
    themeModes: [
      { text: "Always", value: "ALWAYS" },
      { text: "As in the system", value: "AS_IN_THE_SYSTEM" },
      { text: "Never", value: "NEVER" },
    ],
    themeMode: JSON.parse(localStorage.getItem("theme")) || {
      text: "Always",
      value: "ALWAYS",
    },
    fileType: null,
    nickNameError: null,
    emailError: null,
    discordError: null,
    websiteError: null,
    facebookError: null,
    twitterError: null,
    instagramError: null,
    nameError: null,
    surnameError: null,
    descriptionError: null,
  }),
  computed: {
    ...mapGetters({
      getAvatar: "user/getAvatar",
      getBanner: "user/getBanner",
    }),
    disableSaveBtn() {
      return (
        this.nickNameError ||
        this.emailError ||
        this.discordError ||
        this.websiteError ||
        this.facebookError ||
        this.twitterError ||
        this.instagramError ||
        this.nameError ||
        this.surnameError ||
        this.descriptionError
      );
    },
    getEmail: {
      get() {
        return this.$store.getters["user/getEmail"];
      },
      set(val) {
        this.emailError = null;
        const regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.validateRegex(val, regex)) {
          this.emailError = this.$t(errors.INVALID_EMAIL);
        }
        this.updateUser({
          property: "email",
          value: val,
        });
      },
    },
    getDiscord: {
      get() {
        return this.$store.getters["user/getDiscord"];
      },
      set(val) {
        this.discordError = null;
        const regex =
          /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/;
        if (!this.validateRegex(val, regex)) {
          this.discordError = "Wrong discord url";
        }
        this.updateUser({
          property: "discord",
          value: val,
        });
      },
    },
    getWebsite: {
      get() {
        return this.$store.getters["user/getWebsite"];
      },
      set(val) {
        this.websiteError = null;
        const regexWithHttp =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
        const regexWithoutHttp =
          /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
        if (
          !this.validateRegex(val, regexWithHttp) &&
          !this.validateRegex(val, regexWithoutHttp)
        ) {
          this.websiteError = "Wrong website url";
        }
        this.updateUser({
          property: "website",
          value: val,
        });
      },
    },
    getInstagram: {
      get() {
        return this.$store.getters["user/getInstagram"];
      },
      set(val) {
        this.instagramError = null;
        const regex =
          /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
        if (!this.validateRegex(val, regex)) {
          this.instagramError = "Wrong instagram url";
        }
        this.updateUser({
          property: "instagram",
          value: val,
        });
      },
    },
    getTwitter: {
      get() {
        return this.$store.getters["user/getTwitter"];
      },
      set(val) {
        this.twitterError = null;
        const regex =
          /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:\w*#!\/)?(?:pages\/)?(?:[\w\\-]*\/)*([\w\\-]*)/;
        if (!this.validateRegex(val, regex)) {
          this.twitterError = "Wrong twitter url";
        }
        this.updateUser({
          property: "twitter",
          value: val,
        });
      },
    },
    getFacebook: {
      get() {
        return this.$store.getters["user/getFacebook"];
      },
      set(val) {
        this.facebookError = null;
        const regex =
          /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:\w*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\\-]*)?/;
        if (!this.validateRegex(val, regex)) {
          this.facebookError = "Wrong facebook url";
        }
        this.updateUser({
          property: "facebook",
          value: val,
        });
      },
    },
    getDescription: {
      get() {
        return this.$store.getters["user/getDescription"];
      },
      set(val) {
        this.descriptionError = null;
        if (val && val.length > 500) {
          this.descriptionError = "Max bio length 500";
        }
        this.updateUser({
          property: "description",
          value: val,
        });
      },
    },
    getNickName: {
      get() {
        return this.$store.getters["user/getNickName"];
      },
      set(val) {
        this.nickNameError = null;
        const regex = /^(?=[a-zA-Z0-9._]{2,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
        if (!this.validateRegex(val, regex)) {
          this.nickNameError = "Wrong username";
        }
        this.updateUser({
          property: "nickName",
          value: val,
        });
      },
    },
    getName: {
      get() {
        return this.$store.getters["user/getName"];
      },
      set(val) {
        this.nameError = null;
        const regex = /^[a-zA-Z\s]*$/;
        if (!this.validateRegex(val, regex)) {
          this.nameError = "Wrong name";
        }
        if (val && val.length > 40) {
          this.nameError = "Max length 40 symbols";
        }
        this.updateUser({
          property: "name",
          value: val,
        });
      },
    },
    getSurname: {
      get() {
        return this.$store.getters["user/getSurname"];
      },
      set(val) {
        this.surnameError = null;
        const regex = /^[a-zA-Z\s]*$/;
        if (!this.validateRegex(val, regex)) {
          this.surnameError = "Wrong surname";
        }
        if (val && val.length > 40) {
          this.surnameError = "Max length 40 symbols";
        }
        this.updateUser({
          property: "surname",
          value: val,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/getUserInfo",
    }),
    ...mapMutations({
      updateUser: "user/updateUser",
    }),
    setFileType(type) {
      this.fileType = type;
      this.$refs.file.click();
    },
    async updateUserInfo() {
      try {
        this.setLoading(true);
        await api.updateUserInfo({
          description: this.getDescription,
          discord: this.getDiscord,
          email: this.getEmail,
          facebook: this.getFacebook,
          instagram: this.getInstagram,
          name: this.getName,
          nickname: this.getNickName,
          surname: this.getSurname,
          twitter: this.getTwitter,
          website: this.getWebsite,
        });
        this.setSuccess("User info has been updated");
      } catch (e) {
        this.setError(e.response.data.code);
      } finally {
        this.setLoading(false);
      }
    },
    async uploadFile() {
      try {
        this.setLoading(true);
        let file = this.$refs.file.files[0];
        const formData = new FormData();
        formData.append("upload_preset", cloudinary.preset);
        formData.append("file", file);
        formData.append("folder", `${this.fileType}s`);
        const response = await http.post(
          cloudinary.getCloudinaryUploadUrl("image"),
          formData,
          {
            params: {
              isPublic: true,
            },
          }
        );
        let method =
          this.fileType === "Avatar" ? "uploadAvatar" : "uploadBanner";
        await api[method]({
          fileId: response.data.public_id,
          fileUrl: response.data.secure_url,
        });
        await this.getUserInfo();
      } catch (e) {
        this.setError(e.response.data.code || e.response.data.error.message);
      } finally {
        this.$refs.file.value = "";
        this.setLoading(false);
      }
    },
  },
};
</script>
